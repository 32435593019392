import {
  AppShell,
  Burger,
  Button,
  Container,
  Divider,
  Drawer,
  Group,
  Image,
  Menu,
  rem,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import {
  IconBrandWhatsapp,
  IconCircleCheckFilled,
  IconPhoneRinging,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import logo from "./assets/images/logo.png";
import { HugeTitle } from "./Components/Shared/HugeTitle";
import { PageContent } from "./Model/PageContent.model";
import { sendWhatsAppMessage } from "./Services/contact.service";
import { getAllContent } from "./Services/content.service";
import { SERVICES_TITLE } from "./Constants";
import Footer from "./Components/Footer";

interface NavigationItem {
  title: string;
  prominent?: boolean;
  onClick?: () => void;
  icon?: React.ReactNode;
  children?: NavigationItem[];
}

function Layout() {
  const theme = useMantineTheme();
  const desktopMediaQuery = useMediaQuery(
    `(min-width: ${theme.breakpoints.md})`
  );
  const [opened, { toggle, close }] = useDisclosure();
  const [expandedNavigationItemId, setExpandedNavigationItemId] =
    useState<any>(-1);
  const navigate = useNavigate();
  const [navigationMenuItems, setNavigationItems] = useState<any>([
    {
      title: "Home",
      onClick: () => {
        close();
        navigate("/");
      },
    },
    {
      title: SERVICES_TITLE,
      children: [],
    },
    // {
    //   title: "Subjects",
    //   onClick: () => console.log("Subjects clicked"),
    // },
    {
      title: "Reviews",
      onClick: () => {
        close();
        const element = document.getElementById("reviews");
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      },
    },
    {
      title: "Blogs",
      onClick: () => {
        close();
        navigate("/blogs");
      },
    },
    {
      title: "WhatsApp us",
      icon: <IconBrandWhatsapp />,
      prominent: true,
      onClick: () =>
        sendWhatsAppMessage("Hello, I need help with my assignment!"),
    },
  ]);

  async function loadServicesList() {
    try {
      const res = await getAllContent();

      setNavigationItems(
        navigationMenuItems?.map((navigationMenuItem: NavigationItem) => {
          if (navigationMenuItem.title === SERVICES_TITLE) {
            const newServices = {
              ...navigationMenuItem,
              children: (res ?? []).map((serviceItem: PageContent) => ({
                title: serviceItem.header,
                icon: (
                  <IconCircleCheckFilled
                    style={{ width: "14px", height: "14px" }}
                    color={theme.colors.green[7]}
                  />
                ),
                onClick: () => {
                  close();
                  navigate(`/${serviceItem.header}`);
                },
              })),
            };

            return newServices;
          }
          return navigationMenuItem;
        })
      );
    } catch (e) {
      console.log(e);
    }
  }

  function onPhoneNumberClick() {
    window.location.href = `tel:${process.env.REACT_APP_PHNO}`;
  }

  function createNavigationItems({ forDesktop = false }) {
    if (forDesktop) {
      return navigationMenuItems?.map(
        ({ title, onClick, children, prominent, icon }: NavigationItem) => {
          if (!children?.length) {
            return (
              <Button
                variant={prominent ? "filled" : "subtle"}
                size="md"
                onClick={onClick}
                leftSection={icon}
              >
                {title}
              </Button>
            );
          } else {
            return (
              <Menu shadow="md" width={"auto"} withArrow arrowPosition="side">
                <Menu.Target>
                  <Button
                    variant={prominent ? "filled" : "subtle"}
                    leftSection={icon}
                    size="md"
                  >
                    {title}
                  </Button>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Label> {title}</Menu.Label>
                  {children.map(
                    (
                      navigationMenuItemChild: NavigationItem,
                      index: number
                    ) => (
                      <Menu.Item
                        key={"desktop-children-" + index}
                        leftSection={navigationMenuItemChild.icon}
                        onClick={navigationMenuItemChild.onClick}
                        component="a"
                        href={`/${navigationMenuItemChild.title}`}
                      >
                        {navigationMenuItemChild.title}
                      </Menu.Item>
                    )
                  )}
                </Menu.Dropdown>
              </Menu>
            );
          }
        }
      );
    } else {
      return (
        <Stack>
          {navigationMenuItems.map(
            (
              { title, onClick, children, prominent, icon }: NavigationItem,
              index: number
            ) => {
              if (!children?.length) {
                return (
                  <Stack key={"mobile-" + index} style={{ width: "100%" }}>
                    <Button
                      variant={prominent ? "filled" : "subtle"}
                      size="md"
                      onClick={onClick}
                      fullWidth
                      leftSection={icon}
                    >
                      {title}
                    </Button>
                    <Divider />
                  </Stack>
                );
              } else {
                return (
                  <Stack key={"mobile-" + index} style={{ width: "100%" }}>
                    <Button
                      fullWidth
                      variant={prominent ? "filled" : "subtle"}
                      size="md"
                      onClick={() => {
                        setExpandedNavigationItemId((currentIndex: any) => {
                          if (currentIndex === index) return -1;
                          else return index;
                        });
                        if (onClick) onClick();
                      }}
                    >
                      {title}
                    </Button>
                    {expandedNavigationItemId === index && (
                      <Stack gap={8}>
                        {children.map(
                          (
                            navigationMenuItemChild: NavigationItem,
                            childIndex: number
                          ) => (
                            <Button
                              variant="subtle"
                              size="sm"
                              leftSection={navigationMenuItemChild.icon}
                              key={"mobile-" + childIndex + "-child"}
                              onClick={onClick}
                              component="a"
                              href={`/${navigationMenuItemChild.title}`}
                            >
                              {navigationMenuItemChild.title}
                            </Button>
                          )
                        )}
                      </Stack>
                    )}
                    <Divider />
                  </Stack>
                );
              }
            }
          )}
        </Stack>
      );
    }
  }

  useEffect(() => {
    loadServicesList();
  }, []);

  return (
    <>
      <AppShell
        header={{
          height: {
            base: 90,
            md: 110,
          },
        }}
        navbar={{
          width: 300,
          breakpoint: "sm",
          collapsed: { desktop: true, mobile: !opened },
        }}
        padding="md"
      >
        <AppShell.Header>
          <Group h="100%">
            {/* <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" /> */}
            <Container style={{ width: "100%" }} fluid>
              <Group justify="space-between" align="center" w={"100%"} flex={1}>
                <Group visibleFrom="md" onClick={() => navigate("/")}>
                  <Image radius="md" h={90} w={90} src={logo} p={0} m={0} />
                  <HugeTitle
                    style={{ fontSize: "2.3rem" }}
                    c={theme.colors.green[8]}
                  >
                    {process.env.REACT_APP_NAME}
                  </HugeTitle>
                </Group>

                <Group hiddenFrom="md" onClick={() => navigate("/")}>
                  <Image radius="md" h={60} w={60} src={logo} p={0} m={0} />
                  <HugeTitle
                    style={{ fontSize: "1.25rem" }}
                    c={theme.colors.green[8]}
                  >
                    {process.env.REACT_APP_NAME}
                  </HugeTitle>
                </Group>
                <Group gap={"xs"} visibleFrom="md">
                  {createNavigationItems({ forDesktop: true })}
                </Group>
                <Group hiddenFrom="md">
                  <Burger
                    opened={opened}
                    onClick={toggle}
                    aria-label="Toggle Drawer"
                  />

                  <Drawer
                    opened={opened}
                    onClose={toggle}
                    position="right"
                    offset={10}
                    radius={"md"}
                    size="100%"
                  >
                    {createNavigationItems({ forDesktop: false })}
                  </Drawer>
                </Group>
              </Group>
            </Container>
          </Group>
          <Container
            fluid
            p={10}
            style={{ background: theme.colors.green[7] }}
            onClick={onPhoneNumberClick}
          >
            <Group
              align="center"
              justify="center"
              gap={7}
              style={{ cursor: "pointer" }}
            >
              <Text
                c="#fff"
                fw="550"
                style={{ cursor: "pointer" }}
                size={desktopMediaQuery ? "md" : "sm"}
              >
                🚀 Questions? DM us or call
              </Text>
              <IconPhoneRinging
                color="#fff"
                style={{
                  strokeWidth: rem(2),
                  width: rem(desktopMediaQuery ? 20 : 15),
                  height: rem(desktopMediaQuery ? 20 : 15),
                  cursor: "pointer",
                }}
              />
              <Text
                c="#fff"
                fw="bold"
                style={{ cursor: "pointer" }}
                size={desktopMediaQuery ? "md" : "sm"}
              >
                {process.env.REACT_APP_PHNO}
              </Text>
            </Group>
          </Container>
        </AppShell.Header>
        <AppShell.Main px={0} pb={0} style={{ position: "relative" }}>
          <Outlet />

          <Button
            radius={"md"}
            size="md"
            style={{
              position: "fixed",
              bottom: "0%",
              right: "2%",
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
            leftSection={<IconBrandWhatsapp />}
            onClick={() => sendWhatsAppMessage("")}
          >
            WhatsApp us
          </Button>
          <Footer />
        </AppShell.Main>
      </AppShell>
    </>
  );
}

export default Layout;
