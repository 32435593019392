import {
  Container,
  Grid,
  Group,
  Image,
  Paper,
  Stack,
  Stepper,
  useMantineTheme,
} from "@mantine/core";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PageContent } from "../Model/PageContent.model";
import { getContentByTitle } from "../Services/content.service";
import Feature from "./Feature";
import Metrics from "./Metrics";
import DescriptionText from "./Shared/DescriptionText";
import { HugeTitle } from "./Shared/HugeTitle";
import SectionHeader from "./Shared/SectionHeader";
import Testimonials from "./Testimonials";

function Home() {
  const { title } = useParams();
  const [pageContent, setPageContent] = useState<PageContent>();

  async function loadContent(pageTitle: string) {
    try {
      const res = await getContentByTitle(pageTitle);
      // if (res?.data) {
      //   setPageContent(res.data);
      // }
      setPageContent(res);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (title) loadContent(title);
    else setPageContent(undefined);
  }, [title]);

  return (
    <>
      <Container
        mt={{
          base: 50,
          md: 100,
        }}
        fluid
        px={0}
        mx={0}
      >
        <Stack gap={80}>
          <Feature pageContent={pageContent} />
          <Group visibleFrom="md">
            <Metrics />
          </Group>
          <Testimonials />
          <OurService />
          <OurPromise />
        </Stack>
      </Container>
    </>
  );
}

const StepperLabel = ({ title, description }: any) => {
  return (
    <Stack pt={0} mb={50} mt={0}>
      <HugeTitle style={{ fontSize: "2rem" }}>{title}</HugeTitle>
      <DescriptionText>{description}</DescriptionText>
    </Stack>
  );
};

function OurPromise() {
  const theme = useMantineTheme();
  return (
    <>
      <SectionHeader>
        <HugeTitle style={{ fontSize: "2.5rem" }}>We Promise</HugeTitle>
      </SectionHeader>
      <Container>
        {/* <HugeTitle  style={{ fontSize: "2rem" }}>
          We Promise
        </HugeTitle> */}

        <Stepper
          active={Number.MAX_SAFE_INTEGER}
          onStepClick={() => {}}
          orientation="vertical"
          color={theme.colors.green[9]}
        >
          <Stepper.Step
            label={
              <StepperLabel
                title="Step-by-Step Expert Guidance"
                description="Our team of expert tutors provides clear, detailed explanations to help you conquer your assignments with ease."
              />
            }
          />
          <Stepper.Step
            label={
              <StepperLabel
                title="100% Authentic, Plagiarism-Free Work"
                description="We guarantee customized, error-free solutions that are completely original, ensuring your work stands out."
              />
            }
          />
          <Stepper.Step
            label={
              <StepperLabel
                title="Unlimited Revisions for Perfection"
                description="We offer unlimited revisions to ensure your assignment is perfect and meets your exact needs—no compromises."
              />
            }
          />
          <Stepper.Step
            label={
              <StepperLabel
                title="Boost Your Grades with Expert Support"
                description={
                  "With " +
                  process.env.REACT_APP_NAME +
                  ", you get the guidance you need to improve your grades and unlock your academic potential."
                }
              />
            }
          />
          <Stepper.Step
            label={
              <StepperLabel
                title="Risk-Free Money-Back Guarantee"
                description="If you're not fully satisfied with our work, we offer a hassle-free full refund—no questions asked."
              />
            }
          />
          <Stepper.Step
            label={
              <StepperLabel
                title="Guaranteed On-Time Delivery"
                description="We pride ourselves on punctuality, ensuring your project is delivered exactly when you need it—never late."
              />
            }
          />
        </Stepper>
      </Container>
    </>
  );
}

function OurService() {
  const theme = useMantineTheme();
  const hugeTitleStyle = {
    fontSize: "1.5rem",
  };
  const descriptionTextStyle = { fontSize: "1rem", fontWeight: "400" };

  const services = [
    {
      image: "1.jpg",
      title: "Writing and Research Assistance",
      description:
        "Struggling with dissertations, research papers, or in-depth academic tasks? We provide expertly written, plagiarism-free content tailored to your subject. From topic selection to final submission, we ensure excellence and originality.",
    },
    {
      image: "2.jpg",
      title: "Reports and Projects",
      description:
        "Whether it’s precise lab reports or comprehensive guidance on final-year projects, we deliver detailed, accurate, and well-structured solutions to meet your academic needs.",
    },
    {
      image: "3.jpg",
      title: "Presentation and Homework Support",
      description:
        "Captivate your audience with impactful presentations or ease your workload with reliable homework and assignment assistance. We ensure timely delivery and professional results across all subjects.",
    },
    {
      image: "4.jpg",
      title: "Personalized Learning",
      description:
        "Take charge of your education with our personalized tutoring. Our one-on-one online sessions make complex topics easy to understand, helping you achieve your academic goals.",
    },
  ];

  return (
    <>
      <SectionHeader>
        <HugeTitle style={{ fontSize: "2.5rem" }}>Services</HugeTitle>
      </SectionHeader>
      <Container>
        <Grid gutter={"xl"} grow>
          {services.map((service) => (
            <Grid.Col span={{ base: 12, md: 4 }}>
              <Paper radius={0} style={{ width: "100%" }}>
                <Image
                  src={require(`../assets/images/${service.image}`)}
                  height={280}
                  alt="service-image"
                  style={{
                    width: "100%",
                  }}
                  fit="cover"
                />

                <Stack mt={10} p={3} gap={0}>
                  <HugeTitle style={hugeTitleStyle}>{service.title}</HugeTitle>
                  <DescriptionText style={descriptionTextStyle}>
                    <p>{service.description}</p>
                  </DescriptionText>
                </Stack>
              </Paper>
            </Grid.Col>
          ))}
        </Grid>
      </Container>
    </>
  );
}

export default Home;
