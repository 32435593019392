import axios from "axios";
import { Blog } from "../Model/Blog.model";
import sanitizeHtml from "sanitize-html";
import React from "react";

export async function getAllBlogs(): Promise<Blog[]> {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/blog/all`);
  return response.data;
}

export function getBlogByTitle(blogs: Blog[], title?: string) {
  if (title) {
    return blogs?.find((blog: Blog) => encodeBlogTitle(blog.title) === title);
  }
  return null;
}

export function encodeBlogTitle(blogTitle: string) {
  if (blogTitle) {
    return blogTitle.replaceAll(" ", "-").toLowerCase().trim();
  }
  return blogTitle;
}

export function parseHtml(htmlString: string) {
  // Define allowed tags and attributes
  const disallowedTags = new Set([
    "style",
    "html",
    "body",
    "script",
    "meta",
    "title",
  ]);

  // Sanitize the HTML content
  const sanitizedHtml = sanitizeHtml(htmlString, {
    allowedTags: false, // Allow all tags
    exclusiveFilter: (frame: any) => {
      return disallowedTags.has(frame.tag);
    },
    allowedAttributes: {
      "*": ["*"], // Allow all attributes for all tags
    },
    allowedClasses: {
      "*": [], // Disallow classes for all tags
    },
    allowedStyles: {
      "*": {
        // Allow all inline styles for all tags
        "*": [],
      },
    },
    disallowedTagsMode: "discard",
  });

  return React.createElement("div", {
    dangerouslySetInnerHTML: { __html: sanitizedHtml },
  });
}
