import { Container } from "@mantine/core";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import BlogContext from "../../Context/BlogContext";
import { Blog } from "../../Model/Blog.model";
import { useDisclosure } from "@mantine/hooks";
import { encodeBlogTitle, getAllBlogs } from "../../Services/blog.service";

import { HelmetProvider, Helmet } from "react-helmet-async";
import dayjs from "dayjs";
import { formatDate } from "../../Services/common-util.service";
import { blob } from "stream/consumers";

function BlogsLayout() {
  const [blogs, setBlogs] = React.useState<Set<Blog>>();
  const [isLoading, { open: setLoading, close: completeLoading }] =
    useDisclosure(false);

  useEffect(() => {
    fetchAllBlogs();
  }, []);

  const addBlog = useCallback((_blog: any) => {
    if (!blogs) {
      setBlogs(new Set([_blog]));
    } else {
      setBlogs(new Set(blogs).add(_blog));
    }
  }, []);

  const addBlogs = useCallback((_blogs: any[]) => {
    if (!blogs) {
      setBlogs(new Set(_blogs));
    }

    setBlogs((currentBlogs) => {
      const updatedBlogs = new Set(currentBlogs);
      _blogs.forEach((blog) => updatedBlogs.add(blog));
      return updatedBlogs;
    });
  }, []);

  async function fetchAllBlogs() {
    setLoading();
    try {
      const response = await getAllBlogs();
      addBlogs(response);
    } catch (error) {
      console.log(error);
    } finally {
      completeLoading();
    }
  }

  return (
    <Container
      mt={{
        base: 30,
        md: 50,
      }}
      fluid
      px={0}
      mx={0}
    >
      <BlogContext.Provider
        value={{
          blogs: [...(blogs ? Array.from(blogs) : [])],
          isLoading,
          addBlog,
          addBlogs,
        }}
      >
        <HelmetProvider>
          <BlogsMetaData />
        </HelmetProvider>
        <Outlet />
      </BlogContext.Provider>
    </Container>
  );
}

function BlogsMetaData() {
  const { blogs } = useContext(BlogContext);
  const location = useLocation();

  function createBlogBaseLink() {
    return encodeURI(window.location.origin + location.pathname);
  }

  function createBlogLink(blogTitle: string) {
    return encodeURI(createBlogBaseLink() + "/" + encodeBlogTitle(blogTitle));
  }

  function createJsonLDSchema(blog: Blog) {
    const objectSchema = {
      "@context": "https://schema.org",
      "@type": "BlogPostings",
      headline: blog.title,
      author: {
        "@type": "Person",
        name: process.env.REACT_APP_NAME,
      },
      publisher: {
        "@type": "Organization",
        name: process.env.REACT_APP_NAME,
      },
      datePublished: blog.publishedOn
        ? formatDate(blog.publishedOn)
        : formatDate(dayjs().toISOString()),
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": createBlogLink(blog.title),
      },
    };

    return JSON.stringify(objectSchema);
  }

  const Metadata = () => (
    <>
      <Helmet>
        <link rel="canonical" href={createBlogBaseLink()} />
        <meta
          name="keywords"
          content={blogs.flatMap((blog) => blog.tags)?.join(", ")}
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      {(blogs ? Array.from(blogs) : [])?.map((blog: Blog, index: number) => (
        <Helmet key={"metadata" + index}>
          <script type="application/ld+json">{createJsonLDSchema(blog)}</script>
        </Helmet>
      ))}
    </>
  );

  return useMemo(Metadata, [blogs]);
}

export default BlogsLayout;
