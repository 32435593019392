import "@mantine/dates/styles.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "@mantine/carousel/styles.css";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Components/Home";
import Layout from "./Layout";
import BlogsLayout from "./Components/Blogs/BlogsLayout";
import Blogs from "./Components/Blogs/Blogs";
import BlogViewer from "./Components/Blogs/BlogViewer";

function App() {
  useEffect(() => {
    AOS.init({ duration: 800, offset: 300 });
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path=":title" element={<Home />} />
        <Route path="blogs" element={<BlogsLayout />}>
          <Route index element={<Blogs />} />
          <Route path=":title" element={<BlogViewer />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
