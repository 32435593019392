import React, { useEffect, useState } from "react";
import BlogContext from "../../Context/BlogContext";
import {
  encodeBlogTitle,
  getAllBlogs,
  parseHtml,
} from "../../Services/blog.service";
import {
  Card,
  Container,
  Title,
  Text,
  Stack,
  useMantineTheme,
  Spoiler,
  Divider,
  Paper,
  TextInput,
  ActionIcon,
  Loader,
  Group,
  Pill,
} from "@mantine/core";
import { HugeTitle } from "../Shared/HugeTitle";
import Fuse from "fuse.js";
import { IconSearch, IconTag } from "@tabler/icons-react";
import { IconX } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useHover, useMediaQuery } from "@mantine/hooks";
import { Blog } from "../../Model/Blog.model";
import dayjs from "dayjs";
import { formatDate } from "../../Services/common-util.service";

function Blogs() {
  const { blogs, isLoading } = React.useContext(BlogContext);
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const desktopMediaQuery = useMediaQuery(
    `(min-width: ${theme.breakpoints.md})`
  );
  const [searchText, setSearchText] = useState("");

  function getFilteredBlogs() {
    if (!searchText) {
      return blogs;
    } else {
      const fuzzySearch = new Fuse(blogs, {
        keys: ["title", "body", "tags", "publishedOn"],
        threshold: 0.7,
      });
      return fuzzySearch.search(searchText).map((result) => result.item);
    }
  }

  return (
    <Container>
      <Stack>
        <HugeTitle
          style={{ fontSize: desktopMediaQuery ? "2.5rem" : "1.5rem" }}
        >
          Blogs
        </HugeTitle>
        <Paper radius={"xl"} shadow="sm" withBorder>
          <TextInput
            disabled={isLoading}
            placeholder="Search blogs ..."
            size={desktopMediaQuery ? "lg" : "sm"}
            radius={"xl"}
            variant="subtle"
            rightSection={
              searchText ? (
                <ActionIcon
                  variant="subtle"
                  c="gray"
                  radius={"xl"}
                  onClick={() => setSearchText("")}
                >
                  <IconX />
                </ActionIcon>
              ) : (
                <IconSearch />
              )
            }
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Paper>
        {isLoading ? (
          <Container h={"30vh"}>
            <Stack
              align="center"
              justify="center"
              style={{ height: "inherit" }}
            >
              <Loader color={theme.colors.green[7]} />
              <Text>Loading blogs ...</Text>
            </Stack>
          </Container>
        ) : (
          <Stack>
            {getFilteredBlogs().map((blog, index) => (
              <BlogCard
                key={"blog-no-" + index}
                blog={blog}
                desktopMediaQuery={desktopMediaQuery}
              />
            ))}
          </Stack>
        )}
      </Stack>
    </Container>
  );
}

interface BlogCardProps {
  blog: Blog;
  desktopMediaQuery: any;
}

function BlogCard({ blog, desktopMediaQuery }: BlogCardProps) {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const { hovered, ref } = useHover();

  return (
    <Paper
      shadow={hovered ? "none" : "sm"}
      style={{ cursor: "pointer" }}
      p={"md"}
      radius={"md"}
      onClick={() => navigate(encodeBlogTitle(blog.title))}
      ref={ref}
      withBorder={hovered}
    >
      <Stack gap={0}>
        <Stack gap={10}>
          <Title
            fw={510}
            order={desktopMediaQuery ? 2 : 3}
            c={theme.colors.green[8]}
          >
            {blog.title}
          </Title>
          <Divider />
        </Stack>
        <Spoiler
          maxHeight={300}
          hideLabel={undefined}
          showLabel={undefined}
          expanded={false}
        >
          <Text fw={300} size="sm" c={theme.colors.gray[7]}>
            {parseHtml(blog.body)}
          </Text>
        </Spoiler>

        <Stack gap={10}>
          <Text size="sm" fw={400} c={"dimmed"}>
            Published on: {formatDate(blog.publishedOn)}
          </Text>

          {blog.tags?.length ? (
            <Group>
              <Text size="sm" fw={400} c={"dimmed"}>
                Tags :
              </Text>
              {blog.tags.map((tag: string, index: number) => (
                <Group key={"tag-" + index} gap={4}>
                  <IconTag size={14} color={theme.colors.blue[6]} />
                  <Text c={"blue"} size={desktopMediaQuery ? "sm" : "sm"}>
                    {tag}
                  </Text>
                </Group>
              ))}
            </Group>
          ) : (
            <></>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
}

export default Blogs;
