import emailjs from "@emailjs/browser";
import {
  Button,
  Checkbox,
  Divider,
  Grid,
  Group,
  LoadingOverlay,
  NativeSelect,
  Paper,
  rem,
  Stack,
  Text,
  TextInput,
  useCombobox,
  useMantineTheme,
  useMatches,
} from "@mantine/core";
import { DatePickerInput, DatesProvider } from "@mantine/dates";
import { isNotEmpty, useForm } from "@mantine/form";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { IconChevronDown, IconMinus, IconPlus } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { PageContent } from "../../../Model/PageContent.model";
import { ServicesData } from "../../../Model/ServicesData.model";
import { StdCode } from "../../../Model/StdCode.model";
import { logEvent } from "../../../Services/analytics.service";
import { getAllContent, getStdCodes } from "../../../Services/content.service";
import { createEnquiry } from "../../../Services/enquiry.service";
import AlertModal from "../AlertModal";
import DescriptionText from "../DescriptionText";
import { HugeTitle } from "../HugeTitle";
import "./GetHelpForm.css";

interface GetHelpFormProps {
  formSize?: "sm" | "lg";
  flatForm?: boolean;
}

function shouldEnableCaptcha() {
  return process.env.REACT_APP_ENABLE_CAPTCHA === "true";
}

function GetHelpForm({ formSize = "lg", flatForm }: GetHelpFormProps) {
  const theme = useMantineTheme();
  const [getHelpIsLoading, setGetHelpIsLoading] = useState(false);
  const [stdCodes, setStdCodes] = useState<StdCode[]>([]);
  const [selectedStdCode, setSelectedStdCode] = useState<string | null>(null);
  const [shouldShowCaptcha, { open: showCaptcha, close: hideCaptcha }] =
    useDisclosure(false);
  const [isEmailSent, { open: emailSent, close: emailNotSent }] =
    useDisclosure(false);
  const [
    shouldShowAdditionalDetailsFields,
    { toggle: toggleAdditionalDetailsFields },
  ] = useDisclosure(false);
  const [
    shouldShowAlertModal,
    { open: showAlertModal, close: closeAlertModal },
  ] = useDisclosure(false);

  const [serviceData, setServiceData] = useState<ServicesData>({
    services: [],
    defaultService: null,
  });
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });
  const hugeTitleStyles = useMatches({
    base: {
      fontSize: "1.1rem",
      fontWeight: 570,
    },
    md: {
      fontSize: formSize === "lg" ? "1.6rem" : "1.2rem",
      fontWeight: 600,
    },
  });

  const desktopMediaQuery = useMediaQuery(
    `(min-width: ${theme.breakpoints.md})`
  );

  const form = useForm<any>({
    initialValues: {
      usersName: "",
      service: "",
      // title: "",
      email: "",
      stdCode: selectedStdCode,
      contactNumber: "",
      allowMarketingNotifications: true,
      deadline: null,
    },

    validate: {
      service: isNotEmpty("Please select a service"),
      usersName: isNotEmpty("Please provide you name"),
      // title: isNotEmpty("Assignment title is needed"),
      email: (value: any) => {
        if (!value?.trim()) {
          return null;
        } else {
          return /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(value)
            ? null
            : "Enter a valid Email ";
        }
      },
      contactNumber: (value: any) =>
        /^[\d\s+-]+$/.test(value)
          ? null
          : `Phone number must be valid and should not contain any special characters`,
    },
  });

  useEffect(() => {
    loadServicesList();
    loadStdCodes();
  }, []);

  useEffect(() => {
    form.setValues({
      service: serviceData?.defaultService
        ? serviceData?.defaultService?.header
        : "",
    });
  }, [serviceData?.defaultService]);

  async function loadServicesList() {
    try {
      const res = await getAllContent();
      setServiceData({
        services: res ?? [],
        defaultService: null,
      });
    } catch (e) {
      console.log(e);
    }
  }
  async function loadStdCodes() {
    try {
      const response = await getStdCodes();

      if (response) {
        const initialStdCode =
          response.find((stdCode: StdCode) => stdCode.dialCode === "+1")
            ?.dialCode ?? null;
        setSelectedStdCode(initialStdCode);
        form.setFieldValue("stdCode", initialStdCode);
      }

      setStdCodes(response);
    } catch (e) {
      console.log(e);
    }
  }

  function hasAllData() {
    return serviceData?.services?.length >= 0 && stdCodes?.length >= 0;
  }

  function getRenderableSTDCodesForNative() {
    if (stdCodes?.length) {
      return stdCodes.map((stdCode: StdCode, index: number) => (
        <option value={stdCode.dialCode} key={stdCode.dialCode + "-" + index}>
          {`${stdCode.flag} ${stdCode.dialCode}`}
        </option>
      ));
    } else {
      return [];
    }
  }

  function renderAdditionalFieldsConditionally() {
    if (shouldShowAdditionalDetailsFields) {
      return [
        <TextInput
          key={1}
          size={desktopMediaQuery ? formSize : "md"}
          placeholder="Your Email "
          {...form.getInputProps("email")}
          disabled={getHelpIsLoading}
          type="email"
        />,
        <DatesProvider settings={{ timezone: "UTC" }} key={2}>
          <DatePickerInput
            valueFormat="DD MMM YYYY"
            dropdownType="modal"
            placeholder="When is the deadline ?"
            size={desktopMediaQuery ? "lg" : "md"}
            {...form.getInputProps("deadline")}
            radius={"md"}
            disabled={getHelpIsLoading}
            clearable
            minDate={new Date()}
            styles={{
              placeholder: {
                fontSize: desktopMediaQuery
                  ? formSize === "lg"
                    ? theme.fontSizes.lg
                    : theme.fontSizes.sm
                  : theme.fontSizes.md,
              },
              wrapper: {
                height: desktopMediaQuery
                  ? formSize === "lg"
                    ? undefined
                    : "2.2rem"
                  : undefined,
              },
              input: {
                height: "inherit",
                minHeight: "inherit",
                paddingTop: desktopMediaQuery
                  ? formSize === "lg"
                    ? theme.spacing.sm
                    : "0.3rem"
                  : "0.55rem",
                paddingBottom: desktopMediaQuery
                  ? formSize === "lg"
                    ? theme.spacing.sm
                    : "0.3rem"
                  : "0.55rem",
                fontSize: desktopMediaQuery
                  ? formSize === "lg"
                    ? theme.fontSizes.lg
                    : theme.fontSizes.sm
                  : theme.fontSizes.md,
              },
            }}
          />
        </DatesProvider>,
      ];
    } else {
      return [];
    }
  }

  function getServicesDropdownOptions() {
    return [
      <option value="" key={"service - 0"}>
        Choose a service
      </option>,
      ...(serviceData?.services?.map((service: PageContent, index: number) => (
        <option value={service.header} key={"service - " + index + 1}>
          {service.header}
        </option>
      )) ?? []),
    ];
  }

  const handleSubmitWithCaptcha = (values: any) => {
    const captchaService = (window as any).grecaptcha;
    setGetHelpIsLoading(true);
    showCaptcha();

    try {
      captchaService?.ready(() => {
        captchaService.render("captcha-container", {
          sitekey: process.env.REACT_APP_FIREBASE_CAPTCHA_SITE_KEY,
          callback: () => {
            console.log("Captcha Completed");
            hideCaptcha();
            handleSubmit(values);
          },
          "error-callback": () => {
            if (window.prompt("Captcha failed, please try again")) {
              captchaService.reset();
            }
          },
          "expired-callback": () => {
            if (
              window.prompt(
                "Captcha expired, Page will reload, Press OK to continue"
              )
            ) {
              window.location.reload();
            }
          },
        });
      });
    } catch (e) {
      console.log("Something went wrong with captcha");
    }
  };

  async function sendMail(
    usersName: string,
    service: string,
    stdCode: string,
    contactNumber: string,
    allowMarketingNotifications: boolean,
    deadline?: any,
    email?: string
  ) {
    setGetHelpIsLoading(true);
    try {
      const providedEmail = email ?? "Not Provided";
      const providedDeadline = deadline ?? "Not Provided";
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID as string,
        {
          service_type: service,
          project_title: "N/A",
          users_name: usersName,
          contact_number: `${stdCode} ${contactNumber}`,
          deadline: providedDeadline,
          email: providedEmail,
        }
      );
      try {
        await createEnquiry(
          service,
          usersName,
          stdCode,
          contactNumber,
          providedEmail,
          allowMarketingNotifications,
          providedDeadline
        );
        console.log("Enquired");
      } catch (e) {
        console.log("No enquiry, created !!");
      }
      emailSent();
      form.reset();
    } catch (e) {
      console.log(e);
      emailNotSent();
    } finally {
      setGetHelpIsLoading(false);
      showAlertModal();
    }
  }

  const handleSubmit = (values: any) => {
    logEvent("conversion");
    sendMail(
      values.usersName,
      values.service,
      values.stdCode,
      values.contactNumber,
      values.allowMarketingNotifications,
      values.deadline,
      values.email
    );
  };

  return (
    <>
      <Paper
        className="fade-in form-card"
        p={"md"}
        shadow={flatForm ? "none" : "md"}
        withBorder={flatForm}
        radius={"md"}
        style={{ position: "relative" }}
      >
        <Stack gap={15}>
          {!hasAllData() && (
            <LoadingOverlay visible overlayProps={{ radius: "md", blur: 2 }} />
          )}
          <Stack gap={3}>
            <HugeTitle style={hugeTitleStyles}>Request For Help</HugeTitle>
            <DescriptionText c="dimmed" size="xs">
              Send us your request, we'll reply ASAP!
            </DescriptionText>
          </Stack>
          <Divider mb={10} />

          <form
            onSubmit={form.onSubmit(
              shouldEnableCaptcha() ? handleSubmitWithCaptcha : handleSubmit
            )}
            style={{ height: "100%", width: "100%" }}
          >
            <Stack
              style={{ width: "100%", height: "100%" }}
              justify="center"
              gap={desktopMediaQuery ? 20 : 15}
            >
              <NativeSelect
                size={desktopMediaQuery ? formSize : "md"}
                {...form.getInputProps("service")}
                disabled={getHelpIsLoading}
              >
                {getServicesDropdownOptions()}
              </NativeSelect>

              <TextInput
                size={desktopMediaQuery ? formSize : "md"}
                placeholder="Your name please *"
                {...form.getInputProps("usersName")}
                disabled={getHelpIsLoading}
              />
              <Grid gutter={0}>
                <Grid.Col
                  span={{
                    base: 4,
                    md: 3,
                  }}
                >
                  <NativeSelect
                    size={desktopMediaQuery ? formSize : "md"}
                    {...form.getInputProps("stdCode")}
                    disabled={getHelpIsLoading || !stdCodes?.length}
                    styles={{
                      input: {
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        background: "inherit",
                        borderColor: theme.colors.gray[4],
                        borderRight: "none",
                        paddingInlineEnd: 0,
                      },
                      section: {
                        padding: 1,
                      },
                    }}
                    rightSection={
                      <IconChevronDown
                        style={{ width: rem(16), height: rem(16) }}
                      />
                    }
                  >
                    {getRenderableSTDCodesForNative()}
                  </NativeSelect>
                </Grid.Col>
                <Grid.Col
                  span={{
                    base: 8,
                    md: 9,
                  }}
                >
                  <TextInput
                    type="tel"
                    size={desktopMediaQuery ? formSize : "md"}
                    placeholder="Phone Number or Whatsapp Number *"
                    {...form.getInputProps("contactNumber")}
                    disabled={getHelpIsLoading || !stdCodes?.length}
                    styles={{
                      input: {
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      },
                    }}
                  />
                </Grid.Col>
              </Grid>

              {renderAdditionalFieldsConditionally()}

              <Group>
                <Button
                  p={0}
                  leftSection={
                    shouldShowAdditionalDetailsFields ? (
                      <IconMinus size={13} />
                    ) : (
                      <IconPlus size={13} />
                    )
                  }
                  c="blue"
                  fullWidth={false}
                  variant="white"
                  size="xs"
                  onClick={toggleAdditionalDetailsFields}
                >
                  <Text size="xs" style={{ display: "inline" }} m={0} p={0}>
                    ADDITIONAL DETAILS
                  </Text>
                </Button>
              </Group>
              <Checkbox
                defaultChecked
                label={
                  <Text c={"dimmed"} size={desktopMediaQuery ? "sm" : "xs"}>
                    Keep me updated with tips, hacks, and offers!
                  </Text>
                }
                color="teal"
                radius="md"
                {...form.getInputProps("allowMarketingNotifications")}
                disabled={getHelpIsLoading}
                size={
                  desktopMediaQuery
                    ? formSize === "lg"
                      ? "md"
                      : formSize
                    : "xs"
                }
              />
              {shouldShowCaptcha && shouldEnableCaptcha() && (
                <div id="captcha-container"></div>
              )}
              <Button
                size={desktopMediaQuery ? formSize : "md"}
                type="submit"
                loading={getHelpIsLoading}
                disabled={getHelpIsLoading}
              >
                Get Help Now
              </Button>
            </Stack>
          </form>
        </Stack>
      </Paper>
      <AlertModal
        opened={shouldShowAlertModal}
        closeModal={closeAlertModal}
        hasError={!isEmailSent}
      >
        <Text component="p">
          {isEmailSent ? (
            <>
              Your request is in safe hands. Our team is reviewing it and will
              get back to you ASAP—promise! 💼. <br />
              <br />
              In the meantime, sit back, relax, and let us handle the rest. 😊
            </>
          ) : (
            <>
              We couldn’t send your request at the moment. Don’t worry, though!
              Try again in a few hours, and we’ll make sure to get back to you
              ASAP.
              <br /> <br />
              If it’s urgent, feel free to reach us on WhatsApp—we’re always
              ready to help! 📱
            </>
          )}
        </Text>
      </AlertModal>
    </>
  );
}

export default GetHelpForm;
