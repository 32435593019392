import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Group,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconArrowLeftBar } from "@tabler/icons-react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { READABLE_CONTAINER_WIDTH } from "../../Constants";
import BlogContext from "../../Context/BlogContext";
import { Blog } from "../../Model/Blog.model";
import { getBlogByTitle, parseHtml } from "../../Services/blog.service";
import { formatDate } from "../../Services/common-util.service";
import GetHelpForm from "../Shared/GetHelpForm";

function BlogViewer() {
  const { blogs } = useContext(BlogContext);
  const { title } = useParams();
  const navigate = useNavigate();
  const CONTAINER_WIDTH = `calc(${READABLE_CONTAINER_WIDTH} - 100px)`;
  const theme = useMantineTheme();
  const desktopMediaQuery = useMediaQuery(
    `(min-width: ${theme.breakpoints.md})`
  );

  const blogByTitle = getBlogByTitle(blogs, title);

  return (
    <Container
      mt={desktopMediaQuery ? "md" : 50}
      fluid
      style={{
        width: desktopMediaQuery ? CONTAINER_WIDTH : "100%",
      }}
    >
      <Stack gap={40}>
        <Grid style={{ position: "relative" }} gutter={10}>
          <Grid.Col
            span={{
              base: 12,
              md: 8,
            }}
            style={{
              zIndex: 2,
            }}
          >
            <Stack gap={30}>
              <Group justify="space-between">
                <Button
                  color={"blue"}
                  variant="subtle"
                  size={desktopMediaQuery ? "lg" : "sm"}
                  leftSection={<IconArrowLeftBar />}
                  onClick={() => navigate("/blogs")}
                  pl={0}
                >
                  Back
                </Button>
                <Text
                  c={"dimmed"}
                  size={desktopMediaQuery ? "md" : "sm"}
                  fw={500}
                >
                  Published on: {formatDate(blogByTitle?.publishedOn)}
                </Text>
              </Group>

              <Group style={{ width: "100%" }} justify="center" hiddenFrom="md">
                <GetHelpForm formSize="sm" flatForm />
              </Group>

              <Stack gap={10}>
                <Title
                  c={theme.colors.green[8]}
                  order={desktopMediaQuery ? 1 : 2}
                  fw={600}
                >
                  {parseHtml(blogByTitle?.title ?? "")}
                </Title>
                <Divider />
                <Box
                  style={{
                    maxWidth: "inherit",
                    overflowX: "auto",
                  }}
                >
                  {parseHtml(blogByTitle?.body ?? "")}
                </Box>
              </Stack>
            </Stack>
          </Grid.Col>

          <Grid.Col
            span={12}
            style={{
              position: desktopMediaQuery ? "fixed" : "static",
              top: 180,
              width: desktopMediaQuery ? CONTAINER_WIDTH : "100%",
              zIndex: 0,
            }}
          >
            <Group
              style={{ width: "100%" }}
              justify="flex-end"
              visibleFrom="md"
            >
              <GetHelpForm formSize="sm" flatForm />
            </Group>
          </Grid.Col>
        </Grid>
      </Stack>
    </Container>
  );
}

export default BlogViewer;
