import React from "react";
import { Blog } from "../Model/Blog.model";

interface BlogContextType {
  isLoading: boolean;
  blogs: Blog[];
  addBlog: (blog: Blog) => void;
  addBlogs: (blogs: Blog[]) => void;
}

const BlogContext = React.createContext({} as BlogContextType);

export default BlogContext;
