import {
  Container,
  Grid,
  Group,
  List,
  ListItem,
  rem,
  Stack,
  useMantineTheme,
  useMatches,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
  IconCircleCheck,
  IconClipboardOff,
  IconLockPassword,
  IconRobotOff,
} from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";
import { PageContent } from "../../Model/PageContent.model";
import Metrics from "../Metrics";
import DescriptionText from "../Shared/DescriptionText";
import GetHelpForm from "../Shared/GetHelpForm";
import { HugeTitle } from "../Shared/HugeTitle";
import "./Feature.css";

interface FeatureProps {
  pageContent?: PageContent;
}

export default function Feature({ pageContent }: FeatureProps) {
  const theme = useMantineTheme();
  const desktopMediaQuery = useMediaQuery(
    `(min-width: ${theme.breakpoints.md})`
  );
  const [currentServiceIndex, setCurrentServiceIndex] = useState(0);
  const [isSliding, setIsSliding] = useState(false);

  const animationServices = useRef([
    "Dissertation",
    "Programming",
    "Research",
    "Development",
    "Projects",
    "Assignments",
  ]);

  const hugeTitleStyles = useMatches({
    base: {
      fontSize: "1.6rem",
      textAlign: "center",
      fontWeight: 500,
    },
    md: {
      fontSize: "3.2rem",
      fontWeight: 350,
    },
  });

  const animatedServiceStyles = useMatches({
    base: {
      fontSize: "2.5rem",
      textAlign: "center",
      fontWeight: 600,
    },
    md: {
      fontSize: "3.2rem",
      fontWeight: 600,
    },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setIsSliding(true); // Trigger slide-out animation
      setTimeout(() => {
        setCurrentServiceIndex(
          (prevIndex) => (prevIndex + 1) % animationServices.current.length
        ); // Move to the next service
        setIsSliding(false); // Trigger slide-in animation
      }, 1000); // Duration of slide-out animation
    }, 5000); // Total time each service is visible

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <Container>
      <Grid gutter={30} grow>
        <Grid.Col span={{ base: 12, md: 6 }}>
          {pageContent ? (
            <Stack
              gap={20}
              style={{ width: "100%", height: "100%" }}
              justify="center"
            >
              <HugeTitle style={hugeTitleStyles}>
                <div
                  dangerouslySetInnerHTML={{ __html: pageContent.header }}
                ></div>
              </HugeTitle>

              <DescriptionText size={desktopMediaQuery ? "lg" : "md"}>
                <div
                  dangerouslySetInnerHTML={{ __html: pageContent.body }}
                ></div>
              </DescriptionText>
              <KeyFeatures
                theme={theme}
                desktopMediaQuery={desktopMediaQuery}
              />
            </Stack>
          ) : (
            <Stack
              gap={20}
              style={{ width: "100%", height: "100%" }}
              justify="center"
            >
              <Stack gap={0}>
                <HugeTitle style={hugeTitleStyles}>Elevate Your</HugeTitle>
                <HugeTitle
                  style={{ ...hugeTitleStyles, ...animatedServiceStyles }}
                  className={`${
                    isSliding ? "blur-out-contract-bck" : "focus-in-expand-fwd"
                  }`}
                >
                  {animationServices.current[currentServiceIndex]}
                </HugeTitle>
                <HugeTitle style={hugeTitleStyles}>with Expert Help!</HugeTitle>
              </Stack>

              <DescriptionText size={desktopMediaQuery ? "lg" : "md"}>
                Welcome to{" "}
                <strong style={{ color: "#014d40" }}>
                  {process.env.REACT_APP_NAME}
                </strong>
                , the go-to platform trusted by students around the globe for
                reliable and expert assignment help.
              </DescriptionText>

              <KeyFeatures
                theme={theme}
                desktopMediaQuery={desktopMediaQuery}
              />
            </Stack>
          )}
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }} pr={{ base: 0, md: 3 }}>
          <GetHelpForm />
        </Grid.Col>

        <Grid.Col span={{ base: 12 }} pr={{ base: 0 }} hiddenFrom="md">
          <Group hiddenFrom="md" flex={1} my={20}>
            <Metrics />
          </Group>
        </Grid.Col>
      </Grid>
    </Container>
  );
}

function KeyFeatures({ desktopMediaQuery, theme }: any) {
  const keywordsListTextStyles = {
    color: "black",
    fontWeight: 400,
    fontSize: desktopMediaQuery ? "1.1rem" : ".8rem",
  };

  const keywordsListIconStyles = {
    height: rem(desktopMediaQuery ? 25 : 20),
    width: rem(desktopMediaQuery ? 25 : 20),
  };
  return (
    <List center spacing={desktopMediaQuery ? "sm" : "xs"} pt={0} mt={0}>
      <ListItem
        icon={
          <IconCircleCheck
            color={theme.colors.blue[6]}
            style={keywordsListIconStyles}
          />
        }
      >
        <HugeTitle style={keywordsListTextStyles}>
          100% Authentic Work
        </HugeTitle>
      </ListItem>
      <ListItem
        icon={
          <IconRobotOff
            color={theme.colors.green[6]}
            style={keywordsListIconStyles}
          />
        }
      >
        <HugeTitle style={keywordsListTextStyles}>AI-Free Content</HugeTitle>
      </ListItem>
      <ListItem
        icon={
          <IconClipboardOff
            color={theme.colors.orange[6]}
            style={keywordsListIconStyles}
          />
        }
      >
        <HugeTitle style={keywordsListTextStyles}>
          Plagiarism-Free Guarantee
        </HugeTitle>
      </ListItem>
      <ListItem
        icon={
          <IconLockPassword
            color={theme.colors.violet[6]}
            style={keywordsListIconStyles}
          />
        }
      >
        <HugeTitle style={keywordsListTextStyles}>
          Privacy and Confidentiality Assured
        </HugeTitle>
      </ListItem>
    </List>
  );
}
